import { Link } from 'gatsby';
import React from 'react';
import classNames from 'classnames';
import useSiteMetadata from '../hooks/useSiteMetadata';
import PreparedLogo from '../images/logos/Prepared-Logo.svg';

interface NavigationProps {
    mobile?: boolean;
    onCloseMenu?: () => void;
}

const Navigation = ({ mobile, onCloseMenu }: NavigationProps): JSX.Element => {
    const meta = useSiteMetadata();
    const { bookingUrl } = meta || {};
    const today = new Date();
    const isWeekday = today.getDay() >= 1 && today.getDay() <= 5;

    const links = [
        { id: 'shopify', label: 'Shopify', url: '/shopify-agentur-koeln/' },
        { id: 'shopify-plus', label: 'Shopify Plus', url: '/shopify-plus-agentur/' },
        { id: 'migration', label: 'Migration', url: '/shopify-migration/' },
        { id: 'headless', label: 'Headless', url: '/headless-shopify/' },
    ];

    const navClasses = classNames('w-full lg:items-center lg:w-auto lg:flex-row lg:flex', {
        'mt-[80px]': mobile,
    });

    return (
        <div
            css={!mobile ? {
                '[aria-hidden="true"] &': {
                    display: 'none',
                },
            } : {}}
        >
            <div className="flex justify-between items-center">
                <Link
                    to="/"
                    className="hidden lg:block no-underline"
                    aria-label="Prepared"
                >
                    <PreparedLogo className="h-8" />
                </Link>
                <nav className={navClasses}>
                    {links.map(({ id, label, url }) => (
                        <Link
                            key={id}
                            className="nav-item"
                            to={url}
                            onClick={onCloseMenu}
                            css={{
                                '&[aria-current="page"]': {
                                    textDecoration: 'underline',
                                    textUnderlineOffset: '12px',
                                },
                            }}
                        >
                            {label}
                        </Link>
                    ))}
                    {bookingUrl && (
                        <a
                            href={bookingUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hidden lg:flex lg:ml-4 rounded-btn font-menu bg-black-100 px-7 text-white"
                        >
                            {isWeekday && (
                                <span className="w-3 h-3 rounded-full bg-green" />
                            )}
                            Call buchen
                            <svg
                                width="14"
                                height="14"
                                xmlns="http://www.w3.org/2000/svg"
                                className="-rotate-90 stroke-white group-hover:stroke-black"
                                aria-label="Arrow icon"
                            >
                                <title>Arrow Icon</title>
                                <g strokeWidth="1.5" fill="none" fillRule="evenodd">
                                    <path d="M13 3.77V13H3.77M13 13 1 1" />
                                </g>
                            </svg>
                        </a>
                    )}
                </nav>
            </div>
        </div>
    );
};

export default React.memo(Navigation);
