import React, { LegacyRef } from 'react';
import { Link } from 'gatsby';
import Navigation from './Navigation';
import { Drawer, DrawerContent } from './primitives/drawer';
import VisuallyHidden from './primitives/visually-hidden';
import PreparedLogo from '../images/logos/Prepared-Logo.svg';

interface SidebarProps {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const Sidebar = ({ open, setOpen }: SidebarProps): JSX.Element => {
    const [visible, setVisible] = React.useState(true);

    const headerEl = React.useRef<HTMLElement>();
    const positionRef = React.useRef(0);
    const visibleRef = React.useRef(true);

    React.useEffect(() => {
        const hEl = headerEl.current;
        if (!hEl) return;
        const { top, height } = hEl.getBoundingClientRect();
        const topZ = top + height + window.pageYOffset + window.innerHeight;
        const handleScroll = (): void => {
            const moving = window.pageYOffset;
            const isVis = positionRef.current > moving || moving < topZ;
            if (visibleRef.current !== isVis) {
                visibleRef.current = isVis;
                setVisible(isVis);
            }
            positionRef.current = moving;
        };
        window.addEventListener('scroll', handleScroll);
        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const drawerRef = React.useRef<HTMLDivElement>(null);

    return (
        <>
            {!open && (
                <div className="hidden container lg:py-6 lg:block">
                    <Navigation />
                </div>
            )}
            <div ref={drawerRef} />
            <header
                ref={headerEl as LegacyRef<HTMLElement>}
                className="z-10 overflow-hidden top-0 sticky lg:hidden"
                style={{
                    transform: visible ? 'translateY(0)' : 'translateY(-100px)',
                    transition: 'transform 0.7s, opacity 0.7s',
                    transitionDelay: '0.4s',
                    opacity: visible ? '1' : '0',
                    display: open ? 'block' : undefined,
                }}
                css={{
                    pointerEvents: 'all',
                }}
            >
                <div className="container z-10 flex items-center justify-between py-4 lg:py-6">
                    <div className="overflow-hidden">
                        <Link
                            to="/"
                            className="no-underline"
                            aria-label="Prepared"
                        >
                            <PreparedLogo className="h-7" />
                        </Link>
                    </div>
                    <button
                        type="button"
                        css={{
                            width: 'auto',
                            height: 'auto',
                            display: 'flex',
                            padding: '10px 0',
                            flexDirection: 'column',
                        }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        <VisuallyHidden>
                            Toggle Menu
                        </VisuallyHidden>
                        <div
                            className="bg-black-100"
                            css={{
                                width: '40px',
                                height: '1px',
                                marginBottom: '12px',
                                transformOrigin: '45% center',
                                transition: '0.3s',
                            }}
                            style={{
                                transform: open ? 'rotate(45deg) scale(0.8) translateX(10px)' : '',
                            }}
                        />
                        <div
                            className="bg-black-100"
                            css={{
                                width: '40px',
                                height: '1px',
                                transformOrigin: '45% center',
                                transition: '0.3s',
                            }}
                            style={{
                                transform: open ? 'rotate(-45deg) scale(0.8) translateX(10px)' : '',
                            }}
                        />
                    </button>
                </div>
            </header>
            {drawerRef?.current && (
                <Drawer
                    open={open}
                    onOpenChange={(e) => {
                        setOpen(!e);
                    }}
                >
                    <DrawerContent container={drawerRef.current} placement="right" size="full" animation="fade" className="bg-white">
                        <Navigation
                            mobile
                            onCloseMenu={() => {
                                setOpen(false);
                            }}
                        />
                    </DrawerContent>
                </Drawer>
            )}
        </>
    );
};

export default Sidebar;
